import type { Order } from '@commercelayer/sdk';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { AddressFieldTypes } from 'checkout/types/address';
import * as clAPI from 'shared/infra/commerceLayer/orders';
import {
  translateAddressToStore,
  translateOrderToStore,
  translatePaymentMethodToStore,
  translateProductToStore,
} from 'shared/infra/commerceLayer/utils';
import { storeAddressAction } from 'shared/store/address/actions';
import type { AddressPayload } from 'shared/store/address/types';
import {
  loadOrderFailure,
  loadOrderRequest,
  loadOrderSuccess,
  loadProducts,
} from 'shared/store/order/actions';
import type { OrderLineItem } from 'shared/store/order/types';
import { attachPaymentMethodSuccessAction } from 'shared/store/payment/actions';
import type { PaymentState } from 'shared/store/payment/types';

const mapProductsState = (order: Order): OrderLineItem[] =>
  order.line_items
    ?.filter((li) => li.item_type === 'skus' || li.item_type === 'bundles')
    .map(translateProductToStore);

const mapShippingAddressState = (order: Order): AddressPayload => {
  if (!order.shipping_address) {
    return null;
  }

  return translateAddressToStore(
    order.shipping_address,
    AddressFieldTypes.shippingAddress,
  );
};

const mapBillingAddressState = (order: Order): AddressPayload => {
  if (!order.billing_address) {
    return null;
  }

  return translateAddressToStore(
    order.billing_address,
    AddressFieldTypes.billingAddress,
  );
};

const mapPaymentMethodState = (
  order: Order,
): Pick<PaymentState, 'id' | 'reference'> =>
  order.payment_method ? translatePaymentMethodToStore(order) : null;

// TODO: This hook is part of the refactoring initiative.
// This means that it only reflects the old loadOrderSaga without changes.
// To be refactored when redux saga is removed!
export const useGetOrder = (): ((id: string) => Promise<Order>) => {
  const dispatch = useDispatch();

  const setOrderState = useCallback(
    (order: Order) => {
      const orderState = translateOrderToStore(order);
      dispatch(loadOrderSuccess(orderState));

      const productsState = mapProductsState(order);
      dispatch(loadProducts(productsState));

      const shippingAddressState = mapShippingAddressState(order);
      if (shippingAddressState) {
        dispatch(storeAddressAction({ address: shippingAddressState }));
      }

      const billingAddressState = mapBillingAddressState(order);
      if (billingAddressState) {
        dispatch(storeAddressAction({ address: billingAddressState }));
      }

      const paymentMethodState = mapPaymentMethodState(order);
      if (paymentMethodState) {
        dispatch(attachPaymentMethodSuccessAction(paymentMethodState));
      }
    },
    [dispatch],
  );

  // TODO: Refactor after saga removal.
  // only sets order state to loading
  // for backwards compatibility.
  // Should be kept in local component state instead.
  const setOrderStateLoading = useCallback(() => {
    dispatch(loadOrderRequest());
  }, [dispatch]);

  const setOrderStateFailed = useCallback(() => {
    dispatch(loadOrderFailure('failed to load existing order'));
  }, [dispatch]);

  return useCallback(
    async (id: string): Promise<Order> => {
      try {
        setOrderStateLoading();
        const order = await clAPI.getOrder(id);
        setOrderState(order);

        return order;
      } catch (e) {
        setOrderStateFailed();
        throw e;
      }
    },
    [setOrderStateLoading, setOrderState, setOrderStateFailed],
  );
};
