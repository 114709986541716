import type { Order } from '@commercelayer/sdk';
import { action } from 'typesafe-actions';

import type { PaymentMethod } from 'shared/infra/commerceLayer/types';
import {
  OrderTypes,
  type AddInvoiceTypeAction,
  type AddProductAction,
  type AddProductFailureAction,
  type AddProductSuccessAction,
  type ApplyCouponAction,
  type ApplyCouponFailureAction,
  type ApplyCouponSuccessAction,
  type ChangeProductQuantityAction,
  type ChangeProductQuantityFailureAction,
  type ChangeProductQuantitySuccessAction,
  type LoadOrderAction,
  type LoadOrderFailureAction,
  type LoadOrderSuccessAction,
  type LoadProductsAction,
  type OrderDetails,
  type OrderStatuses,
  type PlaceOrderAction,
  type PlaceOrderFailureAction,
  type PlaceOrderSuccessAction,
  type ProposeBusinessAccountAction,
  type RemoveProductAction,
  type RemoveProductFailureAction,
  type RemoveProductSuccessAction,
  type ResetCouponAction,
  type ResetOrderAction,
  type SelectBusinessAccountAction,
  type UnplaceOrderAction,
  type UnplaceOrderFailureAction,
  type UnplaceOrderSuccessAction,
  type UpdateNotificationAction,
} from 'shared/store/order/types';

export const loadOrderSuccess = (
  response: LoadOrderSuccessAction['payload'],
): LoadOrderSuccessAction => action(OrderTypes.LOAD_ORDER_SUCCESS, response);

export const loadOrderRequest = (): LoadOrderAction =>
  action(OrderTypes.LOAD_ORDER_REQUEST);

export const unplaceOrderRequest = (): LoadOrderAction =>
  action(OrderTypes.LOAD_ORDER_REQUEST);

export const loadOrderFailure = (message: string): LoadOrderFailureAction =>
  action(OrderTypes.LOAD_ORDER_FAILURE, { message });

export const loadProducts = (
  response: LoadProductsAction['payload'],
): LoadProductsAction => action(OrderTypes.LOAD_PRODUCTS, response);

export const addInvoiceType = (value: string): AddInvoiceTypeAction =>
  action(OrderTypes.ADD_INVOICE_TYPE, { value });

export const addProduct = (contentfulId: string): AddProductAction =>
  action(OrderTypes.ADD_PRODUCT_REQUEST, {
    contentfulId,
  });

export const addProductSuccess = (message: string): AddProductSuccessAction =>
  action(OrderTypes.ADD_PRODUCT_SUCCESS, { message });

export const addProductFailure = (message: string): AddProductFailureAction =>
  action(OrderTypes.ADD_PRODUCT_FAILURE, { message });

export const proposeBusinessAccount = (
  value: boolean,
): ProposeBusinessAccountAction =>
  action(OrderTypes.PROPOSE_BUSINESS_ACCOUNT, { value });

export const selectBusinessAccount = (
  value: boolean,
): SelectBusinessAccountAction =>
  action(OrderTypes.SELECT_BUSINESS_ACCOUNT, { value });

export const placeOrder = (
  orderId: string,
  locale: string,
  electronicInvoiceDocumentType?: string,
): PlaceOrderAction =>
  action(OrderTypes.PLACE_ORDER_REQUEST, {
    orderId,
    locale,
    electronicInvoiceDocumentType,
  });

export const placeOrderSuccess = (
  status: OrderStatuses = 'placed',
): PlaceOrderSuccessAction =>
  action(OrderTypes.PLACE_ORDER_SUCCESS, { order: { status } });

export const placeOrderFailure = (message: string): PlaceOrderFailureAction =>
  action(OrderTypes.PLACE_ORDER_FAILURE, { message });

export const unplaceOrder = (
  paymentMethod: PaymentMethod,
  loadLocalOrderFn: () => Promise<Order>,
  errorMessage?: string,
  errorCode?: string,
  country?: string,
): UnplaceOrderAction =>
  action(OrderTypes.UNPLACE_ORDER_REQUEST, {
    paymentMethod,
    country,
    errorMessage,
    errorCode,
    loadLocalOrderFn,
  });

export const unplaceOrderSuccess = (): UnplaceOrderSuccessAction =>
  action(OrderTypes.UNPLACE_ORDER_SUCCESS);

export const unplaceOrderFailure = (
  message: string,
): UnplaceOrderFailureAction =>
  action(OrderTypes.UNPLACE_ORDER_FAILURE, { message });

export const applyCoupon = (couponCode: string): ApplyCouponAction =>
  action(OrderTypes.APPLY_COUPON_REQUEST, {
    couponCode,
    updateCouponLimits: true,
  });

export const resetCoupon = (): ResetCouponAction =>
  action(OrderTypes.RESET_COUPON_REQUEST);

export const applyCouponSuccess = (
  order: OrderDetails,
): ApplyCouponSuccessAction =>
  action(OrderTypes.APPLY_COUPON_SUCCESS, { order });

export const applyCouponFailure = (
  errorMessage: string,
): ApplyCouponFailureAction =>
  action(OrderTypes.APPLY_COUPON_FAILURE, { errorMessage });

export const changeProductQuantity = (
  lineItemId: string,
  quantity: number,
  contentfulId: string,
): ChangeProductQuantityAction =>
  action(OrderTypes.CHANGE_PRODUCT_QUANTITY, {
    lineItemId,
    quantity,
    contentfulId,
  });

export const changeProductQuantitySuccess =
  (): ChangeProductQuantitySuccessAction =>
    action(OrderTypes.CHANGE_PRODUCT_QUANTITY_SUCCESS);

export const changeProductQuantityFailure =
  (): ChangeProductQuantityFailureAction =>
    action(OrderTypes.CHANGE_PRODUCT_QUANTITY_FAILURE);

export const removeProduct = (contentfulId: string): RemoveProductAction =>
  action(OrderTypes.REMOVE_PRODUCT, { contentfulId });

export const removeProductSuccess = (
  lineItemId: string,
): RemoveProductSuccessAction =>
  action(OrderTypes.REMOVE_PRODUCT_SUCCESS, { lineItemId });

export const removeProductFailure = (
  lineItemId: string,
  errorMessage: string,
): RemoveProductFailureAction =>
  action(OrderTypes.REMOVE_PRODUCT_FAILURE, { errorMessage, lineItemId });

export const updateNotification = (
  payload: UpdateNotificationAction['payload'],
): UpdateNotificationAction => action(OrderTypes.UPDATE_NOTIFICATION, payload);

export const resetOrder = (): ResetOrderAction =>
  action(OrderTypes.RESET_ORDER);
