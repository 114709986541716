import type { Order } from '@commercelayer/sdk';
import axios, { type AxiosRequestConfig } from 'axios';

import type { AttributionData } from 'checkout/types/checkout';
import type { PaymentPlanResponse } from 'pages/api/order/payment-plan/[id]';
import type { SubscriptionRes } from 'pages/api/order/subscription/[id]';
import { buildHeaders } from 'shared/infra/account/api/services';
import api from 'shared/infra/api/client';
import { authedAxios } from 'shared/infra/http';
import logger from 'shared/services/logger';
import type { PaymentPlan } from 'shared/services/PaymentPlanService';
import type {
  PaymentInstrument,
  Subscription,
} from 'shared/services/ProductService';

export type CLOrderApiResponse = {
  data: {
    id: Order['id'];
    attributes: Partial<Order>;
  };
};

export interface ValidationError extends Error {
  title: string;
  code: string;
  detail: string;
  metadata: Record<string, any>;
}

export interface ValidationResponse {
  data: {
    attributes: {
      ok: boolean;
      errors: ValidationError[];
    };
  };
}

export type PaymentInstrumentRes = {
  data: PaymentInstrument[];
};

export const putPlaceOrder = async (
  orderId: Order['id'],
  attributionData: AttributionData,
): Promise<unknown> => {
  const response = await authedAxios.put<unknown>(
    `/api/order/place/${orderId}`,
    {
      attributionData,
    },
  );
  return response.data;
};

export const postUnplaceOrder = async (
  orderId: Order['id'],
): Promise<Order> => {
  const response = await authedAxios.post<CLOrderApiResponse>(
    `/api/order/unplace/${orderId}`,
  );

  // reconstructing CL sdk response from the CL API
  return {
    id: response.data.data.id,
    ...response.data.data.attributes,
    // TODO - includes
  } as Order;
};

export const postUnplaceOrderToEcomPlatform = async (
  orderId: string,
  conf?: AxiosRequestConfig,
): Promise<CLOrderApiResponse> => {
  const response = await api.post(
    `${process.env.APIGW_URL}/storefront/orders/${orderId}/unplace`,
    {},
    conf,
  );

  return response.data;
};

export const postValidateOrder = async (
  orderId: string,
  merchantCode: string,
): Promise<ValidationResponse> => {
  const { data } = await authedAxios.post(
    `/api/merchants/${merchantCode}/orders/${orderId}/validations`,
  );

  return data as ValidationResponse;
};

export const getOrderSubscription = async (
  orderId: string,
): Promise<Subscription | null> => {
  try {
    const { data } = await axios.get<Subscription>(
      `/api/order/subscription/${orderId}`,
    );

    return data;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return null;
    }

    throw err;
  }
};

export const getPaymentInstruments = async (
  merchantCode: string,
  currencyCode: string,
): Promise<PaymentInstrument[]> => {
  try {
    const response = await authedAxios.get<PaymentInstrumentRes>(
      `/api/merchants/${merchantCode}/payment-instruments?currency_code=${currencyCode}`,
    );
    const { data } = response;

    return data.data;
  } catch (err) {
    logger.error(err, `Could not get payment instruments: ${err.message}`);

    return [];
  }
};

export const getOrderPaymentPlan = async (
  orderId: string,
): Promise<PaymentPlan | null> => {
  try {
    const { data } = await axios.get<PaymentPlan>(
      `/api/order/payment-plan/${orderId}`,
    );

    return data;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return null;
    }

    throw err;
  }
};

export const getOrderPaymentPlanFromEcomPlatform = async (
  orderId: string,
): Promise<PaymentPlan | null> => {
  try {
    const { data: paymentPlanData } = await api.get<PaymentPlanResponse>(
      `${process.env.APIGW_URL}/storefront/orders/${orderId}/payment-plan`,
    );

    return paymentPlanData.data.attributes;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return null;
    }

    throw err;
  }
};

export const getOrderSubscriptionFromEcomPlatform = async (
  orderId: string,
): Promise<Subscription> => {
  const { data: subscriptionData } = await api.get<SubscriptionRes>(
    `${process.env.ECOM_PLATFORM_URL}/api/orders/${encodeURIComponent(
      orderId,
    )}/subscription`,
  );

  const { data: subscription } = subscriptionData;

  return subscription;
};

export const getPaymentInstrumentsFromEcomPlatform = async (
  merchantCode: string,
  currencyCode: string,
  accessToken: string,
): Promise<PaymentInstrumentRes> => {
  const headers = buildHeaders(accessToken);
  const { data } = await api.get<PaymentInstrumentRes>(
    `${process.env.APIGW_URL}/storefront/merchants/${merchantCode}/payment-instruments?currency_code=${currencyCode}`,
    { headers } as AxiosRequestConfig,
  );

  return data;
};

export const patchSubscriptionTaxId = async (
  orderId: string,
  taxId: string,
): Promise<Subscription> => {
  const { data } = await axios.patch<Subscription>(
    `/api/order/subscription/${orderId}`,
    { tax_id: taxId },
  );

  return data;
};
